<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { AppDeviceEnquire } from '@/utils/mixin'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  mixins: [AppDeviceEnquire],
  computed: {
    ...mapActions(['GenerateRoutes']),
    ...mapGetters(['menus'])
  },
  data() {
    return { locale: {}, isLoadSidebar: false }
  },
  methods: {
    dailyCachePurge() {
      const lastCachePurge = localStorage.getItem('lastCachePurge')
      const today = moment().format('YYYY-MM-DD')
      if (today != lastCachePurge) {
        localStorage.clear()
        localStorage.setItem('lastCachePurge', today)
      }
    }
  }
}
</script>
<style>
#app {
  height: 100%;
}
.card-header {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16.38px;
}

.loading-panel {
  text-align: center;
}

.ant-table-pagination.ant-pagination {
  text-align: center;
  float: inherit !important;
}
</style>
