export default store => {
  const ability = store.getters.ability
  return store.subscribe(mutation => {
    switch (mutation.type) {
      case 'SET_CAN_LIST':
        ability.update(store.getters.canList)
        break
    }
  })
}
