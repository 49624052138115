/* eslint-disable no-unused-vars */
import axios from 'axios'
import { message } from 'ant-design-vue'

function errorResponseHandler(error) {
  // if has response show the error
  let _message = ''
  if (error.response) {
    if (typeof error.response.data == 'string') {
      _message = error.response.data
    } else if (error.response.data.message) {
      _message = error.response.data.message
    } else if (error.response.data.error) {
      _message = error.response.data.error
    }
  }
  if (_message) message.error(_message)

  if (error.response && error.response.status === 401) {
    let url = ''
    if (error.response.config) {
      url = error.response.config.url
    }
    const msg = `Lỗi phân quyền: ${url}. Vui lòng thoát ra đăng nhập lại.`
    message.error(msg, 10)
  }
  return Promise.reject(error)
}

export default function getInstanceAxios(baseAPI) {
  // create an axios instance
  const service = axios.create({
    baseURL: baseAPI, // url = base url + request url
    timeout: 300000 // request timeout
  })

  // request interceptor
  service.interceptors.request.use(
    config => {
      config.headers['Authorization'] = `Bearer ` + `${localStorage.getItem('AccessToken')}`
      return config
    },
    error => {
      // do something with request error
      console.log(error) // for debug
      return Promise.reject(error)
    }
  )

  // response interceptor
  service.interceptors.response.use(response => {
    const res = response.data
    return res
  }, errorResponseHandler)

  return service
}
