import MainClient from '../clients/MainClient'

const resource = '/api/customer-specials/vip'

export default {
  getList(query) {
    return MainClient.get(`${resource}/list`, { params: query })
  },

  create(payload) {
    return MainClient.post(`${resource}`, payload)
  },

  update(id, payload) {
    return MainClient.put(`${resource}/${id}`, payload)
  },

  delete(id) {
    return MainClient.delete(`${resource}/${id}`)
  }
}
