/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
//Muc dich: Hien thi loading, chan neu khong co quyen truy cap vao route

import { SSO } from '@30shine-fe/sso-erp'

import router from '../router'
import store from '../store'
import message from 'ant-design-vue/es/message'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const sso = new SSO(process.env.VUE_APP_ENV || 'test')

// eslint-disable-next-line no-undef
router.beforeEach(async (to, from, next) => {
  NProgress.start() // start progress bar

  const accessToken = localStorage.getItem('AccessToken')

  //Check xem nguoi dung da dang nhap chua
  if (accessToken) {
    if (store.getters.menuPaths.length === 0 || !store.getters.user) {
      try {
        const user = await store.dispatch('SetUser')
        await store.dispatch('GenerateRoutes', user.userId)
        await store.dispatch('SetPermissions', user.userId)
      } catch (error) {
        message.error('Có lỗi xảy ra vui lòng đăng nhập lại.')
        localStorage.clear()
        sso.ssoLogout()
        next()
      }
    }
    let menuPaths = store.getters.menuPaths
    let routeConfigs = store.getters.routeConfigs
    if (
      menuPaths &&
      menuPaths.length > 0 &&
      routeConfigs &&
      routeConfigs.length > 0 &&
      to.path != '/404' &&
      to.path != '/500'
    ) {
      const path = to.path.split('/')[1]
      const menuItem = menuPaths.find(x => x.path == to.path)
      const routeItem = routeConfigs.find(x => x.path == to.path)
      if (path === 'hotline') {
        const ruleItem = menuPaths.find(x => x.path == '/hotline')
        if (ruleItem) {
          next()
        } else {
          next('/403')
        }
      }
      if (routeItem && !menuItem && to.path != '/403' && to.path !== '/customer-history') {
        next('/403')
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    //Truong hop chua co accessToken
    try {
      const credentials = await sso.ssoLogin()
      if (credentials) {
        localStorage.setItem('AccessToken', credentials.AccessToken)
        localStorage.setItem('RefreshToken', credentials.RefreshToken)
        localStorage.setItem('IdToken', credentials.IdToken)

        const user = await store.dispatch('SetUser')
        await store.dispatch('GenerateRoutes', user.userId)
        await store.dispatch('SetPermissions', user.userId)
      }
    } catch (e) {
      console.log('error', e)
      message.error('Có lỗi xảy ra vui lòng đăng nhập lại.')
      localStorage.clear()
      sso.ssoLogout()
    }
    next()
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
