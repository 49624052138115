import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
const messages = {
  en: { ...require('./i18n/en.json') },
  vi: { ...require('./i18n/vi.json') }
}

export const locales = [
  {
    title: 'English',
    locale: 'en'
  },
  {
    title: 'Việt Nam',
    locale: 'vi'
  }
]

const defaultLocale = locales[1]

const i18n = new VueI18n({
  locale: defaultLocale.locale,
  messages
})

export async function changeLocale(newLocale, store) {
  if (i18n.locale !== newLocale) {
    console.log(`Change locale to "${newLocale}"`)
    i18n.locale = newLocale || defaultLocale.locale
    store.dispatch('LanguageToggle', { state: i18n.locale })
  } else {
    console.warn(`"${newLocale}" is current locale `)
  }
}

export default i18n
