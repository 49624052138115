import MainClient from '../clients/MainClient'

const resource = '/api/staffs'

export default {
  getListSelect() {
    return MainClient.get(`${resource}/select`)
  },
  getWorkTimeInfo(queryParam, inputDate, currentPage, pageSize) {
    return MainClient.get(
      `${resource}/work-time-info?queryParam=${queryParam}&inputDate=${inputDate}&currentPage=${currentPage}&pageSize=${pageSize}`
    )
  }
}
