<template>
  <div class="footer">
    <div class="copyright">Copyright <a-icon type="copyright" /><span> 2017-2023. All Rights Reserved.</span></div>
  </div>
</template>

<script>
export default {
  name: 'GlobalFooter',
  data() {
    return {}
  }
}
</script>

<style lang="css">
.footer {
  padding: 0 16px;
  margin: 24px 0 24px;
  text-align: center;
}
.copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
</style>
