import Vue from 'vue'
import VueMq from 'vue-mq'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import excel from 'vue-excel-export'
import './core/AntComponents'
import './middlewares/PermissionMiddleware'
import 'ant-design-vue/dist/antd.less'
import { abilitiesPlugin } from '@casl/vue'
import './core/filter.js'
import canPermisions from '@/core/constants'
import UUID from 'vue-uuid'

Vue.use(canPermisions)

Vue.use(abilitiesPlugin, store.getters.ability)
Vue.use(excel)
Vue.use(UUID)

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1250,
    desktop: Infinity
  }
})

Vue.config.productionTip = false

Vue.filter('toCurrency', function(value) {
  if (typeof value !== 'number') {
    return value
  }
  var formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  })
  return formatter.format(value)
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
