import { Layout, PageView } from '@/layouts'

export const constantRoutes = [
  {
    path: '/',
    name: 'index',
    component: Layout,
    meta: { title: 'Kho cung ứng' },
    redirect: '/customer-history',
    children: [
      {
        path: '/customer-history',
        component: () => import('@/views/Home')
      },
      {
        path: '/khach-hang-2020',
        name: 'Inventory2020',
        component: PageView,
        redirect: '/khach-hang/lich-su-giao-dich.html',
        meta: { title: 'Kiểm tồn', icon: 'container' },
        children: [
          {
            meta: { title: 'Thông tin chung' },
            path: '/khach-hang/thong-tin-chung.html',
            name: 'common-info',
            component: () => import('@/views/UsageHistory/Index.vue')
          },
          {
            meta: { title: 'Thông tin chung' },
            path: '/khach-hang/thong-tin-chung/:phone',
            name: 'common-info',
            component: () => import('@/views/UsageHistory/Index.vue')
          },
          {
            meta: { title: 'Thông tin nhân sự' },
            path: '/khach-hang/thong-tin-nhan-su.html',
            name: 'employee-info',
            component: () => import('@/views/EmployeeInfo/Index.vue')
          },
          {
            meta: { title: 'Lịch sử giao dịch' },
            path: '/khach-hang/lich-su-giao-dich.html',
            name: 'usage-history',
            component: () => import('@/views/UsageHistory/Index.vue')
          },
          {
            meta: { title: 'Lịch sử tương tác' },
            path: '/khach-hang/lich-su-tuong-tac.html',
            name: 'interact-history',
            component: () => import('@/views/UsageHistory/Index.vue')
          },
          {
            meta: { title: 'Tra cứu ưu đãi' },
            path: '/khach-hang/uu-dai-khach.html',
            name: 'endow',
            component: () => import('@/views/VoucherUsageDetail/Index.vue')
          },
          {
            meta: { title: 'Đặc biệt' },
            path: '/khach-hang/dac-biet',
            name: 'customerSpecial',
            component: () => import('@/views/CustomerSpecial/CustomerSpecial.vue')
          },
          {
            meta: { title: 'VIP' },
            path: '/khach-hang/VIP',
            name: 'vipCustomer',
            component: () => import('@/views/CustomerSpecial/VipCustomer.vue')
          },
          {
            meta: { title: 'Ghi nhận dữ liệu' },
            path: '/ghi-nhan-du-lieu',
            name: 'collectData',
            component: () => import('@/views/CollectData/Index.vue')
          },
          {
            meta: { title: 'Danh sách gọi hotline' },
            path: '/hotline',
            name: 'ListHotline',
            component: () => import('@/views/Hotline/ListHotline.vue')
          },
          {
            meta: { title: 'Danh sách gọi hotline' },
            path: '/hotline/:type',
            name: 'DetailHotline',
            component: () => import('@/views/Hotline/HotlineDetail.vue')
          }
        ]
      }
    ]
  },

  {
    path: '/403',
    component: () => import('@/views/Exception/403')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/Exception/404')
  },
  {
    path: '/500',
    component: () => import('@/views/Exception/500')
  },
  { path: '*', redirect: '/404' }
]
