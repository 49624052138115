import { Ability } from '@casl/ability'

const getters = {
  device: state => state.app.device,
  menus: state => state.permission.menus,
  ability() {
    return new Ability([], {
      subjectName(subject) {
        return !subject || typeof subject === 'string' ? subject : subject.__type
      }
    })
  }
}

export default getters
