import CustomerInsightClient from '../clients/CustomerInsightClient'
import CustomerViewService from '../clients/CustomerViewService'

export default {
  // GetCustomerInfo(customerId, customerPhone) {
  //   return CustomerInsightClient.get(
  //     `/api/customer-profile/get-profile-checkout?customerId=${customerId}&customerPhone=${customerPhone}`
  //   )
  // },
  GetCustomerInfo(customerId, customerPhone) {
    return CustomerViewService.get(
      `/api/v1/checkout/profile-checkout?customerId=${customerId}&customerPhone=${customerPhone}`
    )
  },
  // updateCustomerData(data) {
  //   return CustomerInsightClient.put(`/api/customer-profile/update-profile-checkout`, data)
  // },
  updateCustomerData(data) {
    return CustomerViewService.put(`api/v1/checkout/profile-checkout`, data)
  },
  fetchQuestion(phone, os) {
    return CustomerInsightClient.get(`/api/customer-question/by-phone?customerPhone=${phone}&os=${os}`)
  },
  postAnswer(body) {
    return CustomerInsightClient.post(`/api/customer-question`, body)
  },
  // GetCustomerShinememberInfo(customerId) {
  //   return CustomerInsightClient.get(
  //     `/api/customer/GetCustomerOverView?customerId=${customerId}&loadBrandTag=false&loadDataBrand=true&loadFeedback=false&loadServed=true`
  //   )
  // }
  GetCustomerShinememberInfo(customerId) {
    return CustomerViewService.get(
      `/api/v1/web-customer/overview?customerId=${customerId}&loadBrandTag=false&loadDataBrand=true&loadFeedback=false&loadServed=true`
    )
  }
}
