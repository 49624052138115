/* eslint-disable */
import { constantRoutes } from '@/router/config'
import { randomString } from '@/utils/string'
import { RepositoryFactory } from '@/api/RepositoryFactory'
const CommonRepository = RepositoryFactory.get('commonRepository')

/**
 *
 * @param {*} menus danh sach menu tra ve tu api
 * @param {*} routes danh sach route cau hinh trong project vue nhung
 * chuyen ve danh array 1 cap(chuyen children ra ngoai)
 */
function filterAsyncRouter(menus, routes) {
  menus.forEach(menu => {
    updateMenuItem(menu, routes)
    if (menu.children && menu.children.length) {
      menu.children.forEach(item => {
        if (item.path == 'javascript:void(0)') {
          return
        }
        updateMenuItem(item, routes, menu)
      })
    }
  })
}

/**
 *
 * @param {*} route object menu tra ve tu api
 * @param {*} constantRoutes Route duoc dinh nghia trong project vue
 * @param {*} menu Object ben ngoai cua children
 */
function updateMenuItem(route, constantRoutes, menu) {
  let item = constantRoutes.find(x => x.path === route.path)
  if (item) {
    route.path = item.path
    route.name = item.name
    route.meta.icon = item.meta.icon
    menu.meta.icon = item.meta.icon
    menu.path = item.parentPath
  } else {
    route.meta = {
      ...route.meta,
      ...{
        target: '_blank'
      },
      icon: 'link'
    }
    if (menu) {
      //route.path = config.ErpEndpoint + route.path
      route.path = route.domain + route.path
    }
    if (menu && !menu.path) {
      menu.path = randomString(20)
    }
  }
}

const listToTree = (list, tree, parentItem) => {
  list.forEach(item => {
    let child = {
      ...item
    }
    if (parentItem) {
      child.parentItem = parentItem
    }
    if (item.children && item.children.length) {
      listToTree(item.children, tree, {
        name: item.name,
        pageId: item.pageId,
        meta: item.meta
      })
    }
    delete child.children
    tree.push(child)
  })
}

//Duyệt toàn bộ cây json
const traverse = o => {
  for (var i in o) {
    if (i === 'pageId') {
      o['path'] = o['pageId']
    }
    if (o[i] !== null && typeof o[i] == 'object') {
      traverse(o[i])
    }
  }
}

/**
 *
 * @param {*} routes Danh sach route duoc cau hinh tren client
 * @param {*} menus Mang menu tra ve tu api
 */
const filterMenu = (routes, menus) => {
  let routesAccess = []
  for (let route of routes) {
    if (route.children && route.children.length > 0) {
      let routesChildrent = []
      let parentInfo
      for (let routeChild of route.children) {
        let menuItem = menus.find(x => x.path === routeChild.path)
        if (menuItem) {
          if (!parentInfo) {
            parentInfo = {
              meta: menuItem.parentItem.meta
            }
          }
          if (menuItem.pageId.includes('([0-9]+)')) {
            continue
          }
          routesChildrent.push({
            meta: {
              title: menuItem.meta ? menuItem.meta.title : '',
              icon: routeChild.meta.icon
            },
            path: routeChild.path,
            name: routeChild.name
          })
        }
      }
      if (routesChildrent.length > 0) {
        routesAccess.push({
          meta: {
            title: parentInfo.meta.title,
            icon: route.meta.icon
          },
          name: route.name,
          path: route.path,
          children: routesChildrent
        })
      }
    }
  }
  return routesAccess
}

const updateRoutes = routes => {
  routes.forEach(element => {
    delete element.component
    delete element.name
    delete element.redirect
  })
}

const permission = {
  state: {
    routeConfigs: [],
    routers: [],
    menuPaths: [],
    canList: [],
    status: false
  },
  getters: {
    routeConfigs: state => state.routeConfigs,
    routers: state => state.routers,
    menuPaths: state => state.menuPaths,
    canList: state => state.canList
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.routers = routers
    },
    SET_ROUTE_CONFIGS: (state, routeConfigs) => {
      state.routeConfigs = routeConfigs
    },
    SET_MENU_PATHS: (state, menuPaths) => {
      state.menuPaths = menuPaths
    },
    SET_CAN_LIST: (state, canList) => {
      state.canList = canList
    },
    SET_STATUS: (state, status) => {
      state.status = status
    }
  },
  actions: {
    async GenerateRoutes({ commit }, userId) {
      let routeConfigs = []
      let routes = []
      let menuPaths = []
      listToTree(constantRoutes, routeConfigs)

      //Lay danh sach menu tu localStorage
      let menuString = localStorage.getItem('menus')
      if (menuString && menuString.length > 0) {
        routes = JSON.parse(menuString)
      } else {
        routes = await CommonRepository.getMenu(process.env.VUE_APP_DOMAIN)
        localStorage.setItem('menus', JSON.stringify(routes))
      }
      //Add path from pageId
      traverse(routes)
      listToTree(routes, menuPaths)

      const currentRoutes = filterMenu(constantRoutes[0].children, menuPaths)

      filterAsyncRouter(routes, routeConfigs)
      updateRoutes(menuPaths)
      updateRoutes(routes)
      commit('SET_ROUTERS', currentRoutes)
      commit('SET_ROUTE_CONFIGS', routeConfigs)
      commit('SET_MENU_PATHS', menuPaths)
    },
    async SetPermissions({ commit }, userId) {
      const permission = await CommonRepository.getPermissions(process.env.VUE_APP_DOMAIN)
      console.log(permission)
      const pageid = permission.filter(c => c.pageId.includes('/don-dat-hang/'))
      if (permission && permission.length > 0) {
        let canArr = []
        permission.forEach(element => {
          canArr.push({
            actions: element.can,
            subject: element.pageId
          })
        })
        commit('SET_CAN_LIST', canArr)
      }
    }
  }
}

export default permission
