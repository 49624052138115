import MainClient from '../clients/MainClient'

const resource = '/api/customer-specials'

export default {
  getList(query) {
    return MainClient.get(`${resource}`, { params: query })
  },

  getById(id) {
    return MainClient.get(`${resource}/${id}`)
  },

  getByCustomerId(customerId) {
    return MainClient.get(`${resource}/customers/${customerId}`)
  },

  getDetail(specialCusId) {
    return MainClient.get(`${resource}/detail/${specialCusId}`)
  },

  create(payload) {
    return MainClient.post(`${resource}`, payload)
  },

  update(id, payload) {
    return MainClient.put(`${resource}/${id}`, payload)
  },

  delete(id) {
    return MainClient.delete(`${resource}/${id}`)
  },

  approve(id, payload) {
    return MainClient.patch(`${resource}/approve/${id}`, payload)
  }
}
