import CampaignClient from '../clients/CampaignClient'

export default {
  getCampaignHistoryByPhone(phone) {
    return CampaignClient.get(`/api/validate-campaign/campaign-history?customerPhone=${phone}`)
  },
  getVoucherListUsageDetail(voucherCode) {
    return CampaignClient.get(`/api/mkt-voucher/list-usage-detail?voucherCode=${voucherCode}`)
  },
  getCampaignHistoryByCustomerId(customerId) {
    return CampaignClient.get(`/api/validate-campaign-dynamo/campaign-history?customerId=${customerId}`)
  }
}
