import Client from '../clients/HotlineClient'

export default {
  getHotline(params) {
    return Client.get(`/hotline/statistic/dynamic`, { params })
  },
  getDetailHotline(params) {
    return Client.get(`/hotline/call/needCall/v2`, { params })
  },
  getMoreHotline(params) {
    const { type, date } = params
    return Client.post(`/hotline/call/request-call`, { type, numberOfCall: 10, date })
  }
}
