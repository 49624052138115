<template>
  <div class="user-wrapper">
    <div class="content-box">
      <a-dropdown>
        <span class="action ant-dropdown-link user-dropdown-menu">
          <span>{{ user }}</span>
          <a-icon type="user" />
        </span>
        <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
          <a-menu-item key="0">
            <a href="javascript:;" @click="handleLogout">
              <a-icon type="logout" />
              <span>Đăng xuất</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import { SSO } from '@30shine-fe/sso-erp'
const sso = new SSO(process.env.VUE_APP_ENV || 'test')

export default {
  name: 'UserMenu',
  components: {},
  computed: {
    user() {
      return this.$store.getters.user.fullName
    }
  },
  methods: {
    handleLogout() {
      this.$confirm({
        title: 'Thông báo',
        content: 'Bạn có muốn đăng xuất không?',
        onOk: () => {
          this.logOut()
          // eslint-disable-next-line no-undef
          sso.ssoLogout()
        },
        onCancel() {}
      })
    },
    logOut() {
      localStorage.clear()
    }
  }
}
</script>
