import Vue from 'vue'
import VueRouter from 'vue-router'
import { constantRoutes } from './config'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes: constantRoutes
})
export default router
