const CanPermisions = {
  add: 'Perm_Add',
  edit: 'Perm_Edit',
  delete: 'Perm_Delete',
  read: 'Perm_Access',
  allowEnrollCrossSalon: 'Perm_AllowEnrollCrossSalon',
  access: 'Perm_Access',
  viewAllData: 'Perm_ViewAllData',
  deleteTimeKeeping: 'Perm_Delete_Timekeeping'
}
CanPermisions.install = function(Vue) {
  Vue.prototype.$permission = CanPermisions
}
export default CanPermisions
