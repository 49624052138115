import { RepositoryFactory } from '@/api/RepositoryFactory'
const CommonRepository = RepositoryFactory.get('commonRepository')

const user = {
  state: {
    user: {
      dateTimeServer: '',
      fullName: '',
      listSalon: [],
      userId: ''
    },
    accessToken: ''
  },

  getters: {
    user: state => state.user,
    accessToken: state => state.accessToken
  },

  mutations: {
    SET_USER_INFO: (state, user) => {
      state.user = Object.freeze(user)
    },
    SET_TOKEN: (state, accessToken) => {
      state.accessToken = accessToken
    }
  },

  actions: {
    async SetUser({ commit }) {
      let user = localStorage.getItem('user')
      if (!user) {
        user = await CommonRepository.getUserSalonInfo()
        localStorage.setItem('user', JSON.stringify(user))
      } else {
        user = JSON.parse(user)
      }
      commit('SET_USER_INFO', Object.freeze(user))
      return user
    },
    Logout: async ({ commit }) => {
      return new Promise(resolve => {
        commit('SET_USER_INFO', { logout: true })
        resolve()
      })
    }
  }
}

export default user
