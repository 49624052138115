//import MainClient from '../clients/MainClient'
import AuthorClient from '../clients/AuthorClient'

export default {
  //Lấy menu chức năng
  getMenu(domain) {
    return AuthorClient.get(`/api/permissions/menus`, { params: { domain: domain } })
  },
  //Lấy thông tin user + salon trong quyền của user
  getUserSalonInfo() {
    return AuthorClient.get(`/api/permissions/salons`)
  },
  //Lấy thông tin quyền
  getPermissions(domain) {
    return AuthorClient.get(`/api/permissions/actions`, { params: { domain: domain } })
  }
}
