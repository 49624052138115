/* eslint-disable no-unused-vars */
import CommonRepository from './repositories/CommonRepository'
import CustomerRepository from './repositories/CustomerRepository'
import CustomerSpecialRepository from './repositories/CustomerSpecialRepository'
import StaffRepository from './repositories/StaffRepository'
import SalonRepository from './repositories/SalonRepository'
import ErrorCutReasonRepository from './repositories/ErrorCutReasonRepository'
import VipCustomerRepository from './repositories/VipCustomerRepository'
import PermissionRepository from './repositories/PermissionRepository'
import CampaignRepository from './repositories/CampaignRepository'
import StoreRepository from './repositories/StoreRepository'
import CustomerInsightRepository from './repositories/CustomerInsightRepository'
import CheckoutRepository from './repositories/CheckoutRepository'
import TimelineRepository from './repositories/TimelineRepository'
import HotlineRepository from './repositories/HotlineRepository'
import FeedbackRepository from './repositories/FeedbackRepository'

const repositories = {
  commonRepository: CommonRepository,
  customerRepository: CustomerRepository,
  customerSpecial: CustomerSpecialRepository,
  staff: StaffRepository,
  salon: SalonRepository,
  errorCutReason: ErrorCutReasonRepository,
  vipCustomer: VipCustomerRepository,
  permission: PermissionRepository,
  campaignRepository: CampaignRepository,
  storeRepository: StoreRepository,
  checkoutRepository: CheckoutRepository,
  customerInsightRepository: CustomerInsightRepository,
  timelineRepository: TimelineRepository,
  hotlineRepository: HotlineRepository,
  feedbackRepository: FeedbackRepository
}

export const RepositoryFactory = {
  get: name => repositories[name]
}
