import MainClient from '../clients/MainClient'
import BillingViewServiceClient from '../clients/BillingViewServiceClient'
import CustomerViewService from '../clients/CustomerViewService'

export default {
  GetTransactionHistoryInfoAtSalon(customerId) {
    return BillingViewServiceClient.get(`/api/v1/billview/customer-bill-history/${customerId}`)
  },

  GetTransactionHistoryInfoAtStoreOnline(customerId, phone) {
    return MainClient.get(`/api/customers/online-transaction-history?CustomerId=${customerId}&phone=${phone}`)
  },

  getByPhone(phone) {
    return MainClient.get(`/api/customers/phone/${phone}`)
  },
  // GetCustomerInfo(phone) {
  //   return MainClient.get(`/api/customer-dynamo/${phone}`)
  // },
  GetCustomerInfo(phone) {
    return CustomerViewService.get(`/api/v1/checkout/info?Phone=${phone}`)
  },
  // GetMemberlogInfo(customerId) {
  //   return MainClient.get(`/api/customers/get-member-log?customerId=${customerId}`)
  // },
  GetMemberlogInfo(customerId) {
    return CustomerViewService.get(`/api/v1/web-customer/get-member-log?customerId=${customerId}`)
  },
  GetProductHistory(customerId) {
    return MainClient.get(`/api/customers/get-product-history?customerId=${customerId}`)
  },
  GetServiceHistory(customerId) {
    return MainClient.get(`/api/customers/get-service-history?customerId=${customerId}`)
  },

  // lịch sử topup moyo
  GetMoyoTopHistory(customerId, pageSize, page) {
    return CustomerViewService.get(
      `/api/v1/customer-history/topup-history?brandName=MOYO&customerId=${customerId}&PageSize=${pageSize}&Page=${page}`
    )
  },
  GetMoyoCumulativeList(customerId) {
    return CustomerViewService.get(`/api/v1/customer-history/customer-save-up?customerId=${customerId}`)
  },
  GetMoyoTopHistoryDetail(billIdMongo) {
    return CustomerViewService.get(`/api/v1/customer-history?billId=${billIdMongo}`)
  }
}
