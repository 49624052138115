import Vue from 'vue'
import Vuex from 'vuex'
import permission from './modules/permission'
import user from './modules/user'
import app from './modules/app'
import getters from './getters'
import abilityPlugin from './modules/ability'
import order from './modules/order'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [abilityPlugin],
  modules: {
    permission: permission,
    user,
    app,
    order
  },
  getters
})
