<template>
  <div class="logo">
    <!-- <img v-if="isSideMenu()" :src="'/static/ic_logo_white.png'" />
    <img v-else-if="!isSideMenu()" :src="'/static/ic_logo_white.png'" style="width: 32px !important" /> -->
  </div>
</template>

<script>
import { mixin, mixinDevice } from '@/utils/mixin'
export default {
  name: 'Logo',
  components: {},
  mixins: [mixin, mixinDevice],

  props: {
    title: {
      type: String,
      default: 'Ant Design Pro',
      required: false
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false
    }
  }
}
</script>
