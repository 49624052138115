export const randomString = length => {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const removeAccents = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}

export const getLastCharterName = name => {
  if (name != null && name != '') {
    var getName = name.split(' ')
    var lastName = getName[getName.length - 1]
    return lastName.slice(0, 1).toUpperCase()
  }
  return ''
}

export const formatPriceWithDot = price => {
  price = Math.round(price)
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
