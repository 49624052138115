import CustomerInsightClient from '../clients/CustomerInsightClient'
import HotlineViewClient from '../clients/HotlineViewClient'
import HotlineServiceClient from '../clients/HotlineServiceClient'
import CustomerViewService from '../clients/CustomerViewService'

export default {
  getListHotlineFeedBack() {
    return HotlineViewClient.get(`/api/CusCareProgStatus/Gets?type=1`)
  },
  createFeedbackCustomer(params) {
    return CustomerInsightClient.post(`/api/CustomerFeedback/AddOrUpdateFeedback`, params)
  },
  // getListFeedbackCustomer(customerId) {
  //   return CustomerInsightClient.get(`/api/CustomerFeedback/GetFeedback?customerId=${customerId}`)
  // },
  getListFeedbackCustomer(customerId) {
    return CustomerViewService.get(`api/v1/web-customer/feedback?customerId=${customerId}`)
  },
  getListTypeConfigFeedbackShop() {
    return HotlineViewClient.get(`/api/FeedbackConfig/Gets?type=2`)
  },
  createFeedbackShop(params) {
    return HotlineServiceClient.post(`/api/CustomerFeedback/CreateSellerFeedback`, params)
  },
  getListFeedbackCustomerShop(params) {
    return HotlineViewClient.get(`/api/SellerFeedback/Gets`, { params })
  }
}
