const order = {
  state: {
    order: {
      inventoryOrderId: undefined,
      inventoryParentId: undefined,
      orderDetails: []
    }
  },

  getters: {
    order: state => state.order
  },

  mutations: {
    SET_ORDER: (state, order) => {
      state.order = order
    }
  },

  actions: {
    saveOrder({ commit }, order, isSaveCache = true) {
      commit('SET_ORDER', order)
      if (isSaveCache && order) {
        localStorage.setItem('order', JSON.stringify(order))
      }
    },

    getOrder() {
      const orderString = localStorage.getItem('order')
      if (orderString && orderString.length > 0) {
        const order = JSON.parse(orderString)

        return order
      }
      return undefined
    },

    updatedOrderDetail({ commit }, orderDetails) {
      const orderString = localStorage.getItem('order')
      if (orderString && orderString.length > 0) {
        let order = JSON.parse(orderString)
        order.orderDetails = orderDetails
        localStorage.setItem('order', JSON.stringify(order))
        commit('SET_ORDER', order)
      }
    }
  }
}

export default order
